import { ProjectResource } from '@/models/projects/ProjectResource'
import { formatDateForBackend } from '@/helpers/formatDate'

export class ProjectUpdateEndDateRequest {
  public end_date: Date | string | null

  constructor({ end_date }: ProjectResource) {
    this.end_date = end_date
  }

  public getRequestData(): { end_date: string } {
    return {
      end_date: formatDateForBackend(this.end_date as Date)
    }
  }

}
