



















import { Vue, Component } from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway'

@Component({
  mixins: [clickaway]
})
export default class ActionButtons extends Vue {

  private open: boolean = false

  private toggleMenu(): void {
    this.open = !this.open
  }

  private closeMenu(): void {
    this.open = false
  }

}
