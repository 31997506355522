import { ProjectResource } from '@/models/projects/ProjectResource'
import { formatDateForBackend } from '@/helpers/formatDate'

export class ProjectUpdateNameRequest {
  public project_name: string

  constructor({ project_name, end_date }: ProjectResource) {
    this.project_name = project_name
  }

  public getRequestData(): { project_name: string } {
    return {
      project_name: this.project_name
    }
  }

}
