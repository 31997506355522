











import { Route } from 'vue-router'
import { Component, Vue } from 'vue-property-decorator'
import { projectModule, stateModule, userModule } from '@/store'

import Loader from '@/components/loaders/Loader.vue'
import ProjectNavigation from '@/components/partials/ProjectNavigation.vue'
import FeedbackForm from './../partials/FeedbackForm.vue'

import { ProjectResource } from '@/models/projects/ProjectResource'
import { AuthUserResource } from '@/models/users/AuthUserResource'
import { ProjectTeamResource } from '@/models/projects/ProjectTeamResource'

@Component({
  components: {
    Loader,
    FeedbackForm,
    ProjectNavigation,
  },
})
export default class ProjectsDetail extends Vue {
  // Route hooks
  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    const ID = parseInt(to.params.project_id, 10)
    try {
      await projectModule.getById(ID)
      projectModule.getUsers()
      next((vm: ProjectsDetail) => {
        // @ts-ignore
        const userLane = window.Userlane || null
        if (userLane && vm.user && vm.project) {
          userLane('identify', vm.user.id, {
            can_create_project: vm.user.canPerformAction('can_start_project'),
            can_edit_team: vm.project.canPerformAction('can_edit_team'),
            is_library_owner: vm.user.canPerformAction('can_upload_document'),
            role:
              vm.project.team.find(
                (member: ProjectTeamResource) => member.user_id === vm.user?.id,
              )?.role ?? '',
            environment: vm.user.environment,
          })
        }
      })
    } catch (e) {
      next({ name: 'projects-overview' })
    } finally {
      stateModule.setLoading(false)
    }
  }

  private destroyed(): void {
    projectModule.detail?.destroy()
    projectModule.setProject()
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private get project(): ProjectResource | null {
    return projectModule.detail
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get isProcessPage(): boolean {
    return (
      (!!this.$route.params.process_id &&
        !this.$route.name?.includes('flexible-document')) ||
      this.$route.name === 'projects-single-detail-connections'
    )
  }
}
